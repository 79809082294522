export default [
  {
    path: "OneTeam",
    meta: {
      title: "项目中心",
      name: "OneTeam",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/OneTeam/index"),
  },
]
export const name = "项目"
