<!--  -->
<template>
  <div class="d-flex h-100">
    <div class="organization-list-wrap">
      <OrganizationTree v-model="organizationId"></OrganizationTree>
    </div>
    <v-divider vertical></v-divider>
    <div class="user-list-wrap">
      <ul class="my-2 ml-4">
        <li v-if="multiple">
          <el-checkbox :indeterminate="isIndeterminate" :value="checkAll" @change="handleCheckAllChange">
            全选
          </el-checkbox>
        </li>
        <el-checkbox-group v-if="multiple" :value="value" @input="handleEmitChange($event)">
          <li v-for="user in userOptions" :key="'radio_' + user.id" class="check-li">
            <el-checkbox :label="user.id">
              <div class="d-flex">
                <avatar :name="user.name" :img="user.avatarUrl" one size="16" fontsize="12"></avatar>
                {{ user.name }}
              </div>
            </el-checkbox>
          </li>
        </el-checkbox-group>
        <el-radio-group v-if="!multiple" :value="value" @input="handleEmitChange($event)">
          <li v-for="user in userOptions" :key="'checkout_' + user.id" class="check-li">
            <el-radio :label="user.id">
              <avatar :name="user.name" :img="user.avatarUrl" one size="16" fontsize="12"></avatar>
              {{ user.name }}
            </el-radio>
          </li>
        </el-radio-group>
        <!-- <v-radio-group v-if="!multiple" :value="value" @input="$emit('input', $event)">
          <v-radio v-for="user in userOptions" :key="'checkout_' + user.id" class="check-li" :value="user.id">
            <template #label>
              <avatar :name="user.name" :img="user.avatarUrl" one size="16" fontsize="12"></avatar>
              {{ user.name }}
            </template>
          </v-radio>
        </v-radio-group> -->
      </ul>
    </div>
    <v-divider vertical></v-divider>
    <div class="userchoosen-list-wrap">
      <p>已选人员：</p>
      <v-chip v-for="user in activeUsers" :key="user.id" class="mr-2 mb-2" label small>
        {{ user.name }}
        <v-icon class="text-body-2" @click="handleRemove(user.id)">mdi-close</v-icon>
      </v-chip>
    </div>
  </div>
</template>

<script>
import getUsers from "@/core/apis/user"
import OrganizationTree from "@/components/OrganizationTree/OrganizationTree.vue"
export default {
  components: { OrganizationTree },
  props: {
    multiple: Boolean,
    value: [Array, Number],
  },
  data() {
    return {
      users: [],
      organizationId: null,
    }
  },
  computed: {
    userOptions() {
      return this.users.filter((user) => !this.organizationId || user.organizationId === this.organizationId)
    },
    activeUsers() {
      const value = this.multiple ? this.value : this.value ? [this.value] : []
      return value.map((id) => {
        return this.users.find((o) => o.id === id) ?? { id: id, name: id }
      })
    },
    visibleSelectedNumber() {
      return this.userOptions.filter((o) => this.value?.includes(o.id)).length
    },
    isIndeterminate() {
      return this.visibleSelectedNumber > 0 && this.visibleSelectedNumber < this.userOptions.length
    },
    checkAll() {
      return this.userOptions.length > 0 && this.visibleSelectedNumber == this.userOptions.length
    },
  },
  watch: {
    multiple(val) {
      if (val && !Array.isArray(this.value)) this.$emit("input", [this.value])
      if (!val && Array.isArray(this.value)) this.$emit("input", null)
    },
  },
  created() {
    getUsers().then((res) => (this.users = res))
  },
  methods: {
    handleEmitChange(ev) {
      this.$emit("input", ev)
      let data = this.multiple
        ? ev.map((id) => this.users.find((o) => o.id === id))
        : this.users.find((o) => o.id === ev)
      this.$emit("change", data)
      console.log(ev)
      console.log(data)
    },
    // 多选全选
    handleCheckAllChange(val) {
      this.handleEmitChange(val ? this.userOptions.map((o) => o.id) : [])
    },
    handleRemove(id) {
      this.handleEmitChange(this.multiple ? this.value.filter((o) => o !== id) : null)
    },
  },
}
</script>

<style lang="scss" scoped>
.organization-list-wrap {
  flex: 1;
  height: 100%;
  overflow: auto;
}
.user-list-wrap {
  width: 200px;
  height: 100%;
  overflow: auto;
  @media screen and (max-width: 600px) {
    width: 160px;
  }
}
.userchoosen-list-wrap {
  width: 270px;
  height: 100%;
  overflow: auto;
  padding: 10px;
  @media screen and (max-width: 600px) {
    display: none;
  }
}
.check-li {
  display: flex;
  padding: 5px 0;
  .el-checkbox {
    line-height: 1;
  }
}
</style>
