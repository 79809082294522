<!--  -->
<template>
  <ul class="r-tree-ul">
    <li v-for="node in nodes" :key="node[valueField]" class="r-tree-li">
      <div class="r-tree-content">
        <span class="r-tree-dashed"></span>
        <span v-ripple class="r-tree-name" :class="isActive(node) && 'r-tree-active-span'" @click="handleClick(node)">
          {{ node[labelField] }}
          <slot :node="node"></slot>
        </span>
      </div>
      <r-tree
        v-if="node.children"
        :nodes="node.children"
        :value="value"
        :multiple="multiple"
        @click="$emit('click', $event)"
        @input="$emit('input', $event)"
      >
        <template #default="{ node }">
          <slot :node="node"></slot>
        </template>
      </r-tree>
    </li>
  </ul>
</template>

<script>
export default {
  name: "r-tree",
  props: {
    nodes: {
      type: Array,
      required: true,
    },
    value: {
      type: [Array, Number, String],
    },
    multiple: Boolean,
    valueField: {
      type: String,
      default: "id",
    },
    labelField: {
      type: String,
      default: "name",
    },
  },
  data() {
    return {}
  },
  watch: {
    multiple(val) {
      if (val && !Array.isArray(this.value)) this.$emit("input", [this.value])
      if (!val && Array.isArray(this.value)) this.$emit("input", null)
    },
  },
  methods: {
    handleClick(node) {
      this.$emit("click", node)
      let multiple = this.multiple
      let value = this.value
      if (multiple) {
        value = value ? value.slice() : []
        let index = value.indexOf(node[this.valueField])
        index > -1 ? value.splice(index, 1) : value.push(node[this.valueField])
        this.$emit("input", value)
      } else {
        this.$emit("input", this.isActive(node) ? null : node[this.valueField])
      }
    },
    isActive(node) {
      if (!this.value) return false
      return this.multiple ? this.value.includes(node[this.valueField]) : this.value === node[this.valueField]
    },
  },
}
</script>

<style lang="scss" scoped>
@import "./RTree.scss";
</style>
