<!--
/**
 * author: vformAdmin
 * email: vdpadmin@163.com
 * website: https://www.vform666.com
 * date: 2021.08.18
 * remark: 如果要分发VForm源码，需在本文件顶部保留此文件头信息！！
 */
-->

<template>
  <div class="container-wrapper" :class="[customClass]">
    <slot></slot>
    <div v-if="designer.selectedId === widget.id && !widget.internal" class="selected-container-wrap">
      <div class="drag-handler">
        <i class="el-icon-rank" :title="i18nt('designer.hint.dragHandler')"></i>
        <i>{{ i18n2t(`designer.widgetLabel.${widget.type}`, `extension.widgetLabel.${widget.type}`) }}</i>
        <i v-if="widget.options.hidden === true" class="iconfont icon-hide"></i>
      </div>

      <div
        class="container-action"
        :style="{ right: widget.options.cardWidth ? `calc(100% - ${widget.options.cardWidth} - 4px) !important` : '' }"
      >
        <i
          class="el-icon-back"
          :title="i18nt('designer.hint.selectParentWidget')"
          @click.stop="selectParentWidget(widget)"
        ></i>
        <i
          v-if="!!parentList && parentList.length > 1"
          class="el-icon-top"
          :title="i18nt('designer.hint.moveUpWidget')"
          @click.stop="moveUpWidget()"
        ></i>
        <i
          v-if="!!parentList && parentList.length > 1"
          class="el-icon-bottom"
          :title="i18nt('designer.hint.moveDownWidget')"
          @click.stop="moveDownWidget()"
        ></i>
        <i
          v-if="widget.type === 'table'"
          class="iconfont icon-insertrow"
          :title="i18nt('designer.hint.insertRow')"
          @click.stop="appendTableRow(widget)"
        ></i>
        <i
          v-if="widget.type === 'table'"
          class="iconfont icon-insertcolumn"
          :title="i18nt('designer.hint.insertColumn')"
          @click.stop="appendTableCol(widget)"
        ></i>
        <i
          v-if="widget.type === 'grid' || widget.type === 'table'"
          class="el-icon-copy-document"
          :title="i18nt('designer.hint.cloneWidget')"
          @click.stop="cloneContainer(widget)"
        ></i>
        <DelPopconfirm ref="delPopover" @ok="removeWidget">
          <template #delIcon>
            <i
              class="el-icon-delete"
              :title="i18nt('designer.hint.remove')"
              @click.stop="$refs.delPopover.showPopover()"
            ></i>
          </template>
        </DelPopconfirm>
      </div>
    </div>
  </div>
</template>

<script>
import i18n from "@/utils/i18n"
import containerMixin from "@/components/form-designer/form-widget/container-widget/containerMixin"
import DelPopconfirm from "@/components/Designer/DelPopconfirm"

export default {
  name: "container-wrapper",
  components: { DelPopconfirm },
  mixins: [i18n, containerMixin],
  props: {
    widget: Object,
    parentWidget: Object,
    parentList: Array,
    indexOfParentList: Number,
    designer: Object,
  },
  computed: {
    customClass() {
      return this.widget.options.customClass ? this.widget.options.customClass.join(" ") : ""
    },
  },
}
</script>

<style lang="scss" scoped>
.container-wrapper {
  position: relative;
  margin-bottom: 20px;

  .table-container,
  .sub-form-container {
    margin: 0 2px;
  }

  .el-card.card-container.is-never-shadow {
    border-radius: 0;
    margin: 2px;
  }

  .selected-container-wrap {
    position: absolute;
    top: -26px;
    right: -2px;
    z-index: 1;
    > div {
      display: inline-block;
    }
  }
  .collapse-container + .selected-container-wrap,
  .tab-container.selected + .selected-container-wrap,
  .table-container.selected + .selected-container-wrap,
  .sub-form-container.selected + .selected-container-wrap {
    right: 0;
  }

  .el-card.card-container.selected + .selected-container-wrap {
    right: -4px;
  }

  .grid-container.el-row {
    padding: 6px 0 !important;
  }

  .container-action {
    height: 22px;
    line-height: 22px;
    background: $--color-primary;
    z-index: 999;
    border-radius: 2px;
    box-shadow: 0px 2px 4px -1px #409effa8;

    i {
      font-size: 14px;
      color: #fff;
      margin: 0 5px;
      cursor: pointer;
    }
  }

  .drag-handler {
    //bottom: -24px;  /* 拖拽手柄位于组件下方，有时无法正常拖动，原因未明？？ */
    height: 22px;
    line-height: 22px;
    background: $--color-primary;
    z-index: 9;
    border-radius: 2px;
    box-shadow: 0px 2px 4px -1px #409effa8;
    margin-right: 2px;

    i {
      font-size: 14px;
      font-style: normal;
      color: #fff;
      margin: 4px;
      cursor: move;
    }
  }
}
</style>
