<template>
  <container-wrapper
    :designer="designer"
    :widget="widget"
    :parent-widget="parentWidget"
    :parent-list="parentList"
    :index-of-parent-list="indexOfParentList"
  >
    <el-card
      :key="widget.id"
      class="card-container"
      :shadow="widget.options.shadow"
      :style="{ width: widget.options.cardWidth + '!important' || '' }"
      :class="[selected ? 'selected' : '', !!widget.options.folded ? 'folded' : '', customClass]"
      @click.native.stop="selectWidget(widget)"
    >
      <div slot="header" class="clear-fix">
        <span>{{ widget.options.label }}</span>
        <i
          v-if="widget.options.showFold"
          class="float-right"
          :class="[!widget.options.folded ? 'el-icon-arrow-down' : 'el-icon-arrow-up']"
          @click="toggleCard"
        ></i>
      </div>
      <draggable
        :list="widget.widgetList"
        v-bind="{ group: 'dragGroup', ghostClass: 'ghost', animation: 200 }"
        handle=".drag-handler"
        :move="checkContainerMove"
        @add="(evt) => onContainerDragAdd(evt, widget.widgetList)"
        @update="onContainerDragUpdate"
      >
        <transition-group name="fade" tag="div" class="form-widget-list">
          <template v-for="(subWidget, swIdx) in widget.widgetList">
            <template v-if="'container' === subWidget.category">
              <component
                :is="subWidget.type + '-widget'"
                :key="subWidget.id"
                :widget="subWidget"
                :designer="designer"
                :parent-list="widget.widgetList"
                :index-of-parent-list="swIdx"
                :parent-widget="widget"
              ></component>
            </template>
            <template v-else>
              <component
                :is="subWidget.type + '-widget'"
                :key="subWidget.id"
                :field="subWidget"
                :designer="designer"
                :parent-list="widget.widgetList"
                :index-of-parent-list="swIdx"
                :parent-widget="widget"
                :design-state="true"
              ></component>
            </template>
          </template>
        </transition-group>
      </draggable>
    </el-card>
  </container-wrapper>
</template>

<script>
import i18n from "@/utils/i18n"
import containerMixin from "@/components/form-designer/form-widget/container-widget/containerMixin"
import Draggable from "vuedraggable"
import ContainerWrapper from "@/components/form-designer/form-widget/container-widget/container-wrapper"
import FieldComponents from "@/components/form-designer/form-widget/field-widget/index"
import refMixinDesign from "@/components/form-designer/refMixinDesign"

export default {
  name: "card-widget",
  componentName: "ContainerWidget",
  components: {
    Draggable,
    ContainerWrapper,
    ...FieldComponents,
  },
  mixins: [i18n, containerMixin, refMixinDesign],
  inject: ["refList"],
  props: {
    widget: Object,
    parentWidget: Object,
    parentList: Array,
    indexOfParentList: Number,
    designer: Object,
  },
  computed: {
    selected() {
      return this.widget.id === this.designer.selectedId
    },

    customClass() {
      return this.widget.options.customClass || ""
    },
  },
  created() {
    this.initRefList()
  },
  methods: {
    /**
     * 检查接收哪些组件拖放，如不接受某些组件拖放，则根据组件类型判断后返回false
     * @param evt
     * @returns {boolean}
     */
    checkContainerMove() {
      return true
    },

    toggleCard() {
      this.widget.options.folded = !this.widget.options.folded
    },

    /**
     * 设置折叠/打开状态
     * @param folded
     */
    setFolded(folded) {
      this.widget.options.folded = !!folded
    },
  },
}
</script>

<style lang="scss" scoped>
.card-container.selected {
  outline: 2px solid $--color-primary !important;
}

.card-container {
  margin: 3px;

  .form-widget-list {
    min-height: 28px;
  }
}

::v-deep .el-card__header {
  padding: 10px 12px;
}

.folded ::v-deep .el-card__body {
  display: none;
}

.clear-fix:before,
.clear-fix:after {
  display: table;
  content: "";
}

.clear-fix:after {
  clear: both;
}

.float-right {
  float: right;
}
</style>
