export default {
  extension: {
    widgetLabel: {
      card: "卡片",
      user: "用户",
      org: "部门",
      mould: "模具",
      process: "工序",
      customer: "客户",
      supplier: "供应商",
      machining: "加工点",
      stock: "存货分类",
    },

    setting: {
      cardFolded: "是否收起",
      cardShowFold: "显示折叠按钮",
      cardWidth: "卡片宽度",
      cardShadow: "显示阴影",

      alertTitle: "标题",
      alertType: "类型",
      description: "辅助性文字",
      closable: "是否可关闭",
      closeText: "关闭按钮文字",
      center: "文字居中",
      showIcon: "显示图标",
      effect: "显示效果",
    },
  },
}
