const files = require.context(".", true, /\.js$/)

var configArray = []
var menus = {}

files.keys().forEach((key) => {
  if (key === "./index.js") return
  const file = files(key)
  configArray = configArray.concat(file.default)
  if (menus[file.name]) {
    menus[file.name] = menus[file.name].concat(file.default)
  } else {
    menus[file.name] = file.default
  }
})
// 嵌套在首页里的
const names = ["Home", "NoticeList", "Application", "OneTeam", "Document", "Todo", "Setting", "ProviderSetting"]
export default [
  {
    path: "/",
    meta: {
      title: "首页",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/index"),
    props: (route) => {
      return { path: route.path, name: route.meta.name }
    },
    children: configArray.filter((c) => names.includes(c.meta?.name)),
  },
  ...configArray.filter((c) => !names.includes(c.meta?.name)),
]
export { menus }
