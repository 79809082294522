<!--  -->
<template>
  <r-tree-simple
    :nodes="nodes"
    :value="value"
    :multiple="multiple"
    :query="query"
    @click="$emit('click', $event)"
    @input="$emit('input', $event)"
  >
    <template #default="{ node }">
      <Avatar v-if="node.manager" :name="node.manager.name" :img="node.manager.avatarUrl" one size="21" />
    </template>
  </r-tree-simple>
</template>

<script>
import getUsers from "@/core/apis/user"
import RTreeSimple from "@/components/RTree/RTreeSimple.vue"
import getOrganizations from "@/core/apis/organization"
export default {
  components: { RTreeSimple },
  props: {
    value: {
      type: [Array, Number, String],
    },
    multiple: Boolean,
    query: String,
  },
  data() {
    return {
      nodes: [],
      users: [],
    }
  },
  async created() {
    let nodes = await getOrganizations()
    this.users = await getUsers()
    this.nodes = await this.setManager(nodes)
  },
  methods: {
    async setManager(nodes) {
      nodes.forEach((node) => {
        let user = this.users.find((user) => user.id === node.managerId)
        if (user) {
          node.manager = {
            name: user.name,
            id: user.id,
            avatarUrl: user.avatarUrl,
          }
        }
        if (node.children) {
          node.children.forEach((n) => this.setManager([n]))
        }
      })
      return nodes
    },
  },
}
</script>

<style lang="scss" scoped></style>
