import Vue from "vue"
import { createPinia, PiniaVuePlugin } from "pinia"
import "./lib/abp/index.js"
// import "./lib/abp/coverAbp"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import vuetify from "./plugins/vuetify"
import "./plugins/axios/index.js"
import "./global.js"
import abpUtil from "./lib/abp/abpUtil"
import "./plugins/element-ui"
import "./assets/scss/style.scss"

// ------vform------
import "babel-polyfill"
// import "./utils/debug-console"
import "./utils/directive"
import "./icons"

import "@/styles/index.scss"
import "@/iconfont/iconfont.css"
import "@/core/iconfont/iconfont.css"

import { loadExtension } from "@/extension/extension-loader"

loadExtension()

if (process.env.NODE_ENV === "development") {
  Vue.config.devtools = true
  Vue.config.productionTip = true
  Vue.mixin({
    methods: {
      logArgs(...args) {
        console.log("logArgs:", args)
      },
    },
  })
}

Vue.use(PiniaVuePlugin)
const pinia = createPinia()

abpUtil.getAbpConfig().then(() => {
  window.app = new Vue({
    router,
    store,
    vuetify,
    pinia,
    render: (h) => h(App),
  }).$mount("#app")
})
