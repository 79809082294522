import { getUrlParam } from "./index"

// true是PC端，false是手机端
export const isPC = (function () {
  var userAgentInfo = navigator.userAgent
  var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"]
  var flag = true
  for (var v = 0; v < Agents.length; v++) {
    if (userAgentInfo.indexOf(Agents[v]) > 0) {
      flag = false
      break
    }
  }
  return flag
})()

export const isPrivate = (name) => name === process.env.VUE_APP_Private

export const isProduction = process.env.NODE_ENV === "production" || process.env.NODE_ENV === "stage"
let isWX = (() => {
  var ua = navigator.userAgent.toLowerCase()
  return ua.indexOf("micromessenger") != -1
})()
let isDD = (() => {
  var ua = navigator.userAgent.toLowerCase()
  return ua.indexOf("dingtalk") != -1
})()

class UtilClass {
  noop = function () {}

  // 环境相关
  url = isProduction ? "" : process.env.VUE_APP_URL // 开发为62335，生产为空
  isPrivate = isPrivate
  isProduction = isProduction
  isPC = isPC
  isWX = isWX
  isDD = isDD
  isIframe = window !== top
  getUrlParam = getUrlParam
}
let $$ = new UtilClass()
// window.$$ = $$;
export default $$
