<!--  -->
<template>
  <r-tree
    :nodes="treeNodes"
    :value="value"
    :multiple="multiple"
    :value-field="valueField"
    :label-field="labelField"
    @click="$emit('click', $event)"
    @input="$emit('input', $event)"
  >
    <template #default="{ node }">
      <slot :node="node"></slot>
    </template>
  </r-tree>
</template>

<script>
import RTree from "./RTree.vue"
function registerTree(root, trees, valueField) {
  return root
    .map((o) => {
      let curChildren = trees.filter((c) => c.parentId === o[valueField])
      o.children = curChildren.length ? registerTree(curChildren, trees, valueField) : []
      return o
    })
    .sort((a, b) => a.sort - b.sort)
}

function getRootNode(node, nodes, valueField) {
  while (node.parentId) {
    node._hide = false
    node = nodes.find((o) => o[valueField] === node.parentId)
  }
  return node
}
function signChildrenVisible(node) {
  node._hide = false
  if (node.children) {
    node.children.forEach((o) => signChildrenVisible(o))
  }
}
function buildFilterTree(nodes, query, valueField, labelField) {
  let filterNodes = nodes.filter((o) => o[labelField].includes(query))
  console.log(filterNodes.map((o) => o.name))
  filterNodes.forEach((node) => signChildrenVisible(node))

  let rootNodes = [...new Set(filterNodes.map((node) => getRootNode(node, nodes, valueField)))].sort(
    (a, b) => a.sort - b.sort
  )
  rootNodes.forEach((node) => (node._hide = false))
  console.log(rootNodes.map((o) => o.name))

  return rootNodes
}

export default {
  name: "r-tree-simple",
  components: { RTree },
  props: {
    nodes: Array,
    value: {
      type: [Array, Number, String],
    },
    multiple: Boolean,
    query: String,
    valueField: {
      type: String,
      default: "id",
    },
    labelField: {
      type: String,
      default: "name",
    },
  },
  data() {
    return {
      treeNodes: [],
    }
  },
  computed: {},
  watch: {
    nodes: "buildTree",
    query() {
      let nodes = this.nodes

      if (this.query) {
        this.nodes.forEach((o) => (o._hide = true))
        let rootNodes = buildFilterTree(nodes, this.query, this.valueField, this.labelField)
        this.treeNodes = registerTree(
          rootNodes,
          nodes.filter((o) => !o._hide),
          this.valueField
        )
      } else {
        this.buildTree()
      }
    },
  },
  created() {
    this.buildTree
  },
  methods: {
    buildTree() {
      let nodes = this.nodes
      let tree = nodes.filter((o) => o.parentId === null)
      this.treeNodes = registerTree(tree, nodes, this.valueField)
    },
  },
}
</script>

<style lang="scss" scoped></style>
