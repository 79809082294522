export default [
  {
    path: "Document",
    meta: {
      title: "文档中心",
      name: "Document",
    },
    redirect: "/Document/Documents",
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Document/index"),
    props: (route) => {
      return { active: route.meta.key }
    },
    children: [
      {
        path: "Documents",
        meta: {
          key: "Documents",
          title: "文档中心",
          name: "Document",
          auth: "Menu.OA.Tenancy.MyDocument",
        },
      },
      {
        path: "MyDocument",
        meta: {
          key: "MyDocument",
          title: "我的文档",
          name: "Document",
          auth: "Menu.OA.Tenancy.MyDocument",
        },
      },
      {
        path: "FolderSetting",
        meta: {
          key: "FolderSetting",
          title: "目录设置",
          name: "Document",
          auth: "Menu.OA.Tenancy.FolderSetting",
        },
      },
    ],
  },
]
export const name = "文档"
