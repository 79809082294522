<template>
  <el-popover v-model="visible" popper-class="del-icon-popper" placement="right-end" width="320" trigger="manual">
    <div>
      <h5 style="font-size: 14px">您确定删除该组件?</h5>
      <p style="color: #f56c6c; font-size: 12px; margin: 10px 0">
        删除后用户已填写的数据将被一并删除且无法恢复，同时引用该组件的所有功能均受影响，请谨慎删除!
      </p>
      <el-button-group style="width: 100%">
        <el-button size="mini" style="width: 50%" @click="confirm()">确定</el-button>
        <el-button size="mini" style="width: 50%" @click="visible = false">取消</el-button>
      </el-button-group>
    </div>
    <slot slot="reference" name="delIcon"></slot>
  </el-popover>
</template>
<script>
export default {
  name: "del-popconfirm",
  data() {
    return { visible: false }
  },
  methods: {
    confirm() {
      this.visible = false
      this.$emit("ok")
    },
    showPopover() {
      this.visible = !this.visible
    },
  },
}
</script>
