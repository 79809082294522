export const cardSchema = {
  type: "card",
  category: "container",
  icon: "card",
  widgetList: [],
  options: {
    name: "",
    label: "卡片",
    hidden: false,
    folded: false,
    showFold: true,
    cardWidth: "100%",
    shadow: "never",
    customClass: "",
  },
}

export const userSchema = {
  type: "user",
  icon: "user",
  formItemFlag: true,
  options: {
    name: "",
    label: "用户",
    labelAlign: "",
    valueType: 0, // 0: 自定义, 1: 公式编辑, 2: 数据联动
    linkage: {}, // 关联选项第一步
    linkageWidgetNames: [], // 数据联动所在的 name 数组，当前组件本身只做 change 时触发
    default2LoginUser: false,
    defaultValue: "",
    placeholder: "",
    columnWidth: "50%",
    size: "",
    labelWidth: null,
    labelHidden: false,
    disabled: false,
    hidden: false,
    clearable: true,
    filterable: false,
    automaticDropdown: false, // 自动下拉
    multiple: false,
    multipleLimit: 0,
    required: false,
    requiredHint: "",
    validation: "",
    validationHint: "",
    valueLabel: "",
    // -------------------
    hideOptionItems: true,
    optionItems: [],
    customClass: "", // 自定义css类名
    // -------------------
    onCreated: "",
    onMounted: "",
    onRemoteQuery: "",
    onChange: "",
    onFocus: "",
    onBlur: "",
    onValidate: "",
  },
}

export const orgSchema = {
  type: "org",
  icon: "org",
  formItemFlag: true,
  options: {
    name: "",
    label: "部门",
    labelAlign: "",
    valueType: 0, // 0: 自定义, 1: 公式编辑, 2: 数据联动
    linkage: {}, // 关联选项第一步
    linkageWidgetNames: [], // 数据联动所在的 name 数组，当前组件本身只做 change 时触发
    defaultValue: "",
    placeholder: "",
    columnWidth: "50%",
    size: "",
    labelWidth: null,
    labelHidden: false,
    disabled: false,
    hidden: false,
    clearable: true,
    filterable: false,
    automaticDropdown: false, // 自动下拉
    multiple: false,
    multipleLimit: 0,
    required: false,
    requiredHint: "",
    validation: "",
    validationHint: "",
    valueLabel: "",
    // -------------------
    hideOptionItems: true,
    optionItems: [],
    customClass: "", // 自定义css类名
    // -------------------
    onCreated: "",
    onMounted: "",
    onRemoteQuery: "",
    onChange: "",
    onFocus: "",
    onBlur: "",
    onValidate: "",
  },
}

export const mouldSchema = {
  type: "select",
  icon: "mould",
  as: "mould",
  formItemFlag: true,
  options: {
    name: "",
    label: "模具",
    labelAlign: "",
    valueType: 0, // 0: 自定义, 1: 公式编辑, 2: 数据联动
    linkage: {}, // 关联选项第一步
    linkageWidgetNames: [], // 数据联动所在的 name 数组，当前组件本身只做 change 时触发
    defaultValue: "",
    placeholder: "",
    columnWidth: "50%",
    size: "",
    labelWidth: null,
    labelHidden: false,
    disabled: false,
    hidden: false,
    optionType: 1, // 选项类型
    dsName: "获取模具列表", // 数据源名称
    dsParentWidget: "", // 数据源数据依赖于父级组件选择的数据，举例：模具下的零件，零件依赖于模具
    parentWidgetKey: "", // 父组件对应的键的名称
    labelKey: "label",
    valueKey: "value",
    auxiliaryTextKey: "auxiliaryText",
    clearable: true,
    filterable: true,
    remote: true,
    automaticDropdown: false, // 自动下拉
    multiple: false,
    multipleLimit: 0,
    required: false,
    requiredHint: "",
    validation: "",
    validationHint: "",
    valueLabel: "",
    // -------------------
    hideOptionItems: false,
    disabledOptionItems: true,
    optionItems: [],
    customClass: "", // 自定义css类名
    labelIconClass: null,
    labelIconPosition: "rear",
    labelTooltip: null,
    // -------------------
    onCreated: "",
    onMounted: "",
    onRemoteQuery: "",
    onChange: "",
    onFocus: "",
    onBlur: "",
    onValidate: "",
  },
}

export const processSchema = {
  type: "select",
  icon: "process",
  as: "process",
  formItemFlag: true,
  options: {
    name: "",
    label: "工序",
    labelAlign: "",
    valueType: 0, // 0: 自定义, 1: 公式编辑, 2: 数据联动
    linkage: {}, // 关联选项第一步
    linkageWidgetNames: [], // 数据联动所在的 name 数组，当前组件本身只做 change 时触发
    defaultValue: "",
    placeholder: "",
    columnWidth: "50%",
    size: "",
    labelWidth: null,
    labelHidden: false,
    disabled: false,
    hidden: false,
    optionType: 1, // 选项类型
    dsName: "获取工序列表", // 数据源名称
    dsParentWidget: "", // 数据源数据依赖于父级组件选择的数据，举例：模具下的零件，零件依赖于模具
    parentWidgetKey: "", // 父组件对应的键的名称
    labelKey: "label",
    valueKey: "value",
    auxiliaryTextKey: "auxiliaryText",
    clearable: true,
    filterable: true,
    remote: true,
    automaticDropdown: false, // 自动下拉
    multiple: false,
    multipleLimit: 0,
    required: false,
    requiredHint: "",
    validation: "",
    validationHint: "",
    valueLabel: "",
    // -------------------
    hideOptionItems: false,
    disabledOptionItems: true,
    optionItems: [],
    customClass: "", // 自定义css类名
    labelIconClass: null,
    labelIconPosition: "rear",
    labelTooltip: null,
    // -------------------
    onCreated: "",
    onMounted: "",
    onRemoteQuery: "",
    onChange: "",
    onFocus: "",
    onBlur: "",
    onValidate: "",
  },
}

export const customerSchema = {
  type: "select",
  icon: "customer",
  as: "customer",
  formItemFlag: true,
  options: {
    name: "",
    label: "客户",
    labelAlign: "",
    valueType: 0, // 0: 自定义, 1: 公式编辑, 2: 数据联动
    linkage: {}, // 关联选项第一步
    linkageWidgetNames: [], // 数据联动所在的 name 数组，当前组件本身只做 change 时触发
    defaultValue: "",
    placeholder: "",
    columnWidth: "50%",
    size: "",
    labelWidth: null,
    labelHidden: false,
    disabled: false,
    hidden: false,
    optionType: 1, // 选项类型
    dsName: "获取客户列表", // 数据源名称
    dsParentWidget: "", // 数据源数据依赖于父级组件选择的数据，举例：模具下的零件，零件依赖于模具
    parentWidgetKey: "", // 父组件对应的键的名称
    labelKey: "label",
    valueKey: "value",
    auxiliaryTextKey: "auxiliaryText",
    clearable: true,
    filterable: true,
    remote: true,
    automaticDropdown: false, // 自动下拉
    multiple: false,
    multipleLimit: 0,
    required: false,
    requiredHint: "",
    validation: "",
    validationHint: "",
    valueLabel: "",
    // -------------------
    hideOptionItems: false,
    disabledOptionItems: true,
    optionItems: [],
    customClass: "", // 自定义css类名
    labelIconClass: null,
    labelIconPosition: "rear",
    labelTooltip: null,
    // -------------------
    onCreated: "",
    onMounted: "",
    onRemoteQuery: "",
    onChange: "",
    onFocus: "",
    onBlur: "",
    onValidate: "",
  },
}

export const supplierSchema = {
  type: "select",
  icon: "supplier",
  as: "supplier",
  formItemFlag: true,
  options: {
    name: "",
    label: "供应商",
    labelAlign: "",
    valueType: 0, // 0: 自定义, 1: 公式编辑, 2: 数据联动
    linkage: {}, // 关联选项第一步
    linkageWidgetNames: [], // 数据联动所在的 name 数组，当前组件本身只做 change 时触发
    defaultValue: "",
    placeholder: "",
    columnWidth: "50%",
    size: "",
    labelWidth: null,
    labelHidden: false,
    disabled: false,
    hidden: false,
    optionType: 1, // 选项类型
    dsName: "获取供应商列表", // 数据源名称
    dsParentWidget: "", // 数据源数据依赖于父级组件选择的数据，举例：模具下的零件，零件依赖于模具
    parentWidgetKey: "", // 父组件对应的键的名称
    labelKey: "label",
    valueKey: "value",
    auxiliaryTextKey: "auxiliaryText",
    clearable: true,
    filterable: true,
    remote: true,
    automaticDropdown: false, // 自动下拉
    multiple: false,
    multipleLimit: 0,
    required: false,
    requiredHint: "",
    validation: "",
    validationHint: "",
    valueLabel: "",
    // -------------------
    hideOptionItems: false,
    disabledOptionItems: true,
    optionItems: [],
    customClass: "", // 自定义css类名
    labelIconClass: null,
    labelIconPosition: "rear",
    labelTooltip: null,
    // -------------------
    onCreated: "",
    onMounted: "",
    onRemoteQuery: "",
    onChange: "",
    onFocus: "",
    onBlur: "",
    onValidate: "",
  },
}

export const machiningSchema = {
  type: "select",
  icon: "machining",
  as: "machining",
  formItemFlag: true,
  options: {
    name: "",
    label: "加工点",
    labelAlign: "",
    valueType: 0, // 0: 自定义, 1: 公式编辑, 2: 数据联动
    linkage: {}, // 关联选项第一步
    linkageWidgetNames: [], // 数据联动所在的 name 数组，当前组件本身只做 change 时触发
    defaultValue: "",
    placeholder: "",
    columnWidth: "50%",
    size: "",
    labelWidth: null,
    labelHidden: false,
    disabled: false,
    hidden: false,
    optionType: 1, // 选项类型
    dsName: "获取加工点列表", // 数据源名称
    dsParentWidget: "", // 数据源数据依赖于父级组件选择的数据，举例：模具下的零件，零件依赖于模具
    parentWidgetKey: "", // 父组件对应的键的名称
    labelKey: "label",
    valueKey: "value",
    auxiliaryTextKey: "auxiliaryText",
    clearable: true,
    filterable: true,
    remote: true,
    automaticDropdown: false, // 自动下拉
    multiple: false,
    multipleLimit: 0,
    required: false,
    requiredHint: "",
    validation: "",
    validationHint: "",
    valueLabel: "",
    // -------------------
    hideOptionItems: false,
    disabledOptionItems: true,
    optionItems: [],
    customClass: "", // 自定义css类名
    labelIconClass: null,
    labelIconPosition: "rear",
    labelTooltip: null,
    // -------------------
    onCreated: "",
    onMounted: "",
    onRemoteQuery: "",
    onChange: "",
    onFocus: "",
    onBlur: "",
    onValidate: "",
  },
}

export const stockSchema = {
  type: "cascader",
  icon: "stock",
  as: "stock",
  formItemFlag: true,
  options: {
    name: "",
    label: "存货分类",
    defaultValue: "",
    placeholder: "请选择",
    size: "",
    labelWidth: null,
    labelHidden: false,
    columnWidth: "50%",
    disabled: false,
    hidden: false,
    hiddenRulesWidget: [], // 关联选项隐藏所涉及的字段
    clearable: true,
    filterable: true,
    multiple: false,
    checkStrictly: false, // 可选择任意一级选项，默认不开启
    optionType: 1, // 选项类型
    dsName: "获取存货分类列表", // 数据源名称
    dsParentWidget: "", // 数据源数据依赖于父级组件选择的数据
    parentWidgetKey: "", // 父组件对应的键的名称
    optionAppId: "", // 指定应用
    optionFormId: "", // 指定表单
    labelKey: "label",
    valueKey: "value",
    childrenKey: "children",
    optionItems: [],
    required: false,
    requiredHint: "",
    customRule: "",
    customRuleHint: "",
    // -------------------
    customClass: "", // 自定义css类名
    labelIconClass: null,
    labelIconPosition: "rear",
    labelTooltip: null,
    valueLabel: "",
    // -------------------
    onCreated: "",
    onMounted: "",
    onChange: "",
    onFocus: "",
    onBlur: "",
    onValidate: "",
  },
}
