import abpUtil from "@/lib/abp/abpUtil"
import $$ from "@/core/utils/utilClass"
import { customerServicePhone } from "@/core/config/mlmw"
import Vue from "vue"
function replaceStateCode() {
  location.replace(abpUtil.funcUrlDel("code"))
}
function checkAuth(auth) {
  if (typeof auth === "undefined") return true
  return typeof auth === "string" ? abp.auth.isGranted(auth) : typeof auth === "function" ? auth() : auth
}

export default {
  useMeta(to, from, next) {
    if (to.meta.title) {
      document.title = to.meta.title
    }
    next()
  },
  async checkLoginAuth(to, from, next) {
    const { isWX } = $$
    const notLoggedIn = !abp.session.userId
    if (to.meta.title) {
      document.title = to.meta.title
    }
    const whiteList = ["success", "error", "login"]
    // 这个判断必须放在最前面，错误页允许随意访问
    if (whiteList.includes(to.name) || to.meta.noNeedAuthorize) {
      next()
      return
    }
    let code = $$.getUrlParam("code")
    if (isWX) {
      // 如果有param中有code，只能允许code做从微信拿回登录信息去后台进行登录
      if (code) {
        let rep = await Vue.prototype.$post.post(`/api/services/app/weixin/authenticateByCode?code=${code}`)
        // 设置微信信息
        if (rep.sessionId) {
          const tokenExpireDate = new Date(new Date().getTime() + 1000 * rep.expireInSeconds || 1)
          abp.utils.setCookieValue(abpUtil.authorization.wx_sessionId, rep.sessionId, tokenExpireDate, abp.appPath)
        }
        if (rep.accessToken) {
          abpUtil.setToken(rep)
        } else if (to.meta.noNeedAuthorize) {
          // configWx();
        } else {
          let path = location.pathname + location.hash
          window.history.replaceState({}, document.title, path)
          let title = "当前微信未绑定系统账号"
          let errMsg = `【${to.meta.title + to.path}】页面需要权限登录才可访问,请刷新重试`
          $$.replaceError({ msg: title, detail: errMsg })
          return
        }
        // 清除code，重新获取session与微信信息
        replaceStateCode()
      } else if (notLoggedIn) {
        return abpUtil.goAuthRedirect()
      }
    } else if (code) {
      replaceStateCode()
    } else if (notLoggedIn) {
      return abpUtil.goAuthRedirect()
    }

    next()
  },
  checkPower(to, from, next) {
    // console.log('beforeEach-----------------')
    // 只有明确指出power为false才去到错误页
    let auth = to.meta.auth
    if (!(typeof auth === "undefined")) {
      // 支持直接给boolean或需要判断的string
      auth = checkAuth(auth)
      if (!auth) {
        let msg = to.meta.msg
        if (!msg) {
          let title = to.meta.title
          if (title) {
            msg = "您暂无权限访问" + (title ? `《${title}》` : "")
          }
        }
        return next(
          `/error?msg=${encodeURI(msg)}&detail=${encodeURI(
            to.meta.detail || `请联系公司管理员或模来模往技术客服，${customerServicePhone}`
          )}`
        )
      }
    }
    next()
  },
}
