import { getPageResult } from "@/plugins/axios"
let ops = null
export default async function getUsers(refresh) {
  if (!ops || refresh) {
    ops = (async () => {
      let res = await getPageResult(`/api/services/app/User/GetAllList`, {
        where: `JobDateEnd=null and (Status==null or !Status.Contains("NotVerified"))`,
      })
      return res.reverse()
    })()
  }
  return await ops
}
