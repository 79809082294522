<template>
  <div>
    <el-upload
      v-bind="$attrs"
      action="https://www.imouldyun.com/api/cloudstorage/cloudfile/uploadfiles"
      :headers="headers"
      :data="uploadData"
      :accept="isImage ? 'image/*' : ''"
      :multiple="multiple"
      :list-type="simple ? 'picture' : 'picture-card'"
      :on-preview="handlePictureCardPreview"
      :on-remove="handleRemove"
      :on-success="handleSuccess"
      :on-error="handleError"
      :on-exceed="handleExceed"
      :file-list="fileList"
      :before-upload="beforeAvatarUpload"
      :show-file-list="!simple"
      :class="['uploader-wrap', simple ? 'simple-uploader' : 'list-uploader']"
      v-on="$listeners"
    >
      <!-- fileList.length < $attrs.limit ? "hide el-upload--picture-card" -->
      <template v-if="simple">
        <template v-if="value">
          <img
            :src="fileList[0].url"
            class="img_inner"
            :style="{ width: (width || size) + 'px', height: (height || size) + 'px', maxWidth: '100%' }"
          />
          <div class="simple-btn-wrap">
            <v-btn
              icon
              small
              style="background-color: rgba(128, 128, 128, 0.5)"
              @click.stop="handleRemove(fileList[0])"
            >
              <!-- <v-icon small>mdi-delete</v-icon> -->
              <i class="el-icon-delete"></i>
            </v-btn>
          </div>
        </template>
        <div
          v-else
          :style="{ width: (width || size) + 'px', height: (height || size) + 'px', maxWidth: '100%' }"
          class="d-flex ai-center jc-center picture-simple-card"
        >
          <i class="el-icon-plus"></i>
        </div>
      </template>
      <i v-else class="el-icon-plus"></i>
    </el-upload>
    <el-dialog :visible.sync="dialogVisible" append-to-body>
      <img width="100%" :src="dialogImageUrl" alt="" />
    </el-dialog>
  </div>
</template>
<script>
import { previewImage, getUrl } from "@/core/utils"
export default {
  props: {
    isImage: { type: Boolean, default: true },
    value: [Array, String, Object],
    isPath: { type: Boolean, default: false },
    simple: { type: Boolean, default: false },
    size: { type: [String, Number], default: 100 },
    width: { type: [String, Number], default: 100 },
    height: { type: [String, Number], default: 100 },
  },
  data() {
    return {
      uploadData: {},
      dialogImageUrl: "",
      dialogVisible: false,
      disabled: false,
      headers: {
        "X-XSRF-TOKEN": abp.security.antiForgery.getToken(),
        Authorization: "Bearer " + abp.auth.getToken(),
        "X-Authorization": "Bearer " + abp.auth.getRefreshToken(),
      },
      fileList: [],
      fileCache: [],
      // percent: 100
    }
  },
  computed: {
    multiple() {
      var multiple = this.$attrs.multiple
      return typeof multiple === "boolean" ? multiple : true
    },
  },
  watch: {
    value: "setFileListByValue",
  },
  created() {
    this.setFileListByValue()
  },
  methods: {
    beforeAvatarUpload(file) {
      // const isJPG = file.type === "image/jpeg";
      const isLt2M = file.size / 1024 / 1024 < 600

      // if (!isJPG) {
      //   this.$message.error("上传头像图片只能是 JPG 格式!");
      // }
      if (!isLt2M) {
        this.$message.error("上传头像图片大小不能超过 600MB!")
      }
      this.uploadData.files = file
      return isLt2M
    },
    handleRemove(file) {
      if (this.simple) {
        this.$emit("input", null)
      } else {
        var val = this.value.filter((el) => (this.isPath ? el : el.filePath) != file.url)
        this.$emit("input", val)
      }
    },
    handlePictureCardPreview(file) {
      previewImage(
        file.url,
        this.fileList.map((o) => o.url)
      )
      // this.dialogImageUrl = file.url;
      // this.dialogVisible = true;
    },
    handleSuccess(response, file, fileList) {
      // 缓存起来只有当全部成功才向上抛出
      if (Array.isArray(response.result) && !this.multiple && this.isPath) {
        response.result = response.result[0]
      }
      this.fileCache = this.fileCache.concat(this.isPath ? response.result.filePath : response.result)
      if (fileList.every((el) => el.status === "success")) {
        this.$message({ type: "success", message: "文件上传成功！" })
        if (!this.simple) {
          this.$emit("input", this.value.concat(this.fileCache))
        } else {
          this.$emit("input", this.fileCache[0])
        }
        this.fileCache = []
      }
      // error: null
      // result: {success: true, filePath: "https://itianwangmlmwtest.oss-cn-shanghai.aliyuncs…20/05/05/d78bc16f-28e4-4767-ac4d-86f95cf935bd.jpg", fileName: "006cqKYCly1g3mlvv6kr4j30j60j7ncm.jpg", fileSize: 74.3525390625, msg: null, …}
      // success: true
      // targetUrl: null
      // unAuthorizedRequest: false
    },
    handleError() {
      this.$message({ type: "error", message: "文件上传失败！" })
    },
    handleExceed(files, fileList) {
      this.$message.warning(
        `当前限制选择 ${this.$attrs.limit} 个文件，本次选择了 ${files.length} 个文件，共选择了 ${
          files.length + fileList.length
        } 个文件`
      )
    },
    initFile(file) {
      if (this.isPath) file = { filePath: file }
      return {
        name: file.fileName || file.filePath,
        url: getUrl(file.filePath),
        uid: file.fileId || file.filePath,
      }
    },
    setFileListByValue() {
      if (!this.value && !this.simple) return this.$emit("input", [])
      if (!this.simple) {
        this.fileList = this.value ? this.value.map((file) => this.initFile(file)) : []
      } else {
        this.fileList = this.value ? [this.initFile(this.value)] : []
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.img_inner {
  box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
  border-radius: 3px;
  object-fit: cover;
}
// 解决有文字导致高度不对
.uploader-wrap {
  line-height: 0;
  // font-size: 0;
  vertical-align: top;
  position: relative;
  display: inline-block;
}
.simple-btn-wrap {
  display: none;
}
.uploader-wrap:hover {
  .simple-btn-wrap {
    display: block;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
    left: 50%;
  }
}
.simple-uploader {
  max-width: 100%;
  ::v-deep .el-upload {
    max-width: 100%;
  }
  .picture-simple-card {
    // box-shadow: 0 2px 4px rgb(0 0 0 / 12%), 0 0 6px rgb(0 0 0 / 4%);
    border-radius: 3px;
    background-color: #fbfdff;
    border: 1px dashed #c0ccda;
  }
  .el-icon-plus {
    font-size: 24px;
  }
}
</style>
