import { defineStore } from "pinia"
import getUsers from "@/core/apis/user"
export const useAvatarStore = defineStore("avatar", {
  state: () => ({
    userAvatars: {},
  }),
  actions: {
    async init() {
      if (abp.session.userId) {
        const users = await getUsers()
        const userAvatars = {}
        users.map((user) => {
          userAvatars[user.id.toString()] = user.avatarUrl
        })
        this.userAvatars = userAvatars
      }
    },
  },
})
