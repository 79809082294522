import Vue from "vue"
// import { extend } from "lodash-es"
import { extend } from "@/core/utils/index"
import $$ from "@/core/utils/utilClass"

class Util {
  authorization = {
    encrptedAuthTokenName: "token",
    wxSessionId: "wx_sessionId",
    // 'enc_auth_token'
  }

  deleteCookie() {
    abp.utils.deleteCookie(this.authorization.wx_sessionId, abp.appPath)
    abp.utils.deleteCookie(this.authorization.encrptedAuthTokenName, abp.appPath)
    abp.auth.clearToken()
  }
  async weixinSPAOauth() {
    this.deleteCookie()
    location.replace(`https://www.imouldyun.com/weixin/spaoauth?returnurl=${encodeURIComponent(location.href)}`)
  }
  async goAuthRedirect() {
    const { isWX } = $$
    if (process.env.NODE_ENV === "production" || process.env.NODE_ENV === "stage") {
      if (isWX) {
        location.href = `https://auth.imouldyun.com/weixin/oauth?returnUrl=${location.href}`
        this.weixinSPAOauth()
      } else {
        // `?returnUrl=${location.href.replace(location.origin, "")}`
        if (process.env.NODE_ENV === "production") {
          location.href = `https://auth.imouldyun.com/account/login?returnUrl=${location.href}`
        } else {
          location.href =
            Vue.prototype.$rootUrl +
            "/Account/Login?returnUrl=" +
            encodeURIComponent(location.href.replace(location.origin, ""))
        }
      }
    } else {
      const data = {
        returnurl: "/",
        tenancyName: process.env.VUE_APP_TENANCYNAME || "default",
        userName: process.env.VUE_APP_USERNAME || "admin",
        password: process.env.VUE_APP_PASSWORD || "333666999",
        vercode: "",
      }
      const rep = await Vue.prototype.$axios.post("/api/auth/account/authenticate", data)
      const tokenExpireDate = new Date(new Date().getTime() + 1000 * rep.expireInSeconds)
      abp.auth.setToken(rep.accessToken, rep.refreshToken, tokenExpireDate)
      abp.utils.setCookieValue(
        this.authorization.encrptedAuthTokenName,
        rep.encryptedAccessToken,
        tokenExpireDate,
        abp.appPath
      )
      location.reload()
    }
  }
  setToken(rep) {
    const tokenExpireDate = new Date(new Date().getTime() + 1000 * rep.expireInSeconds || 1)
    abp.auth.setToken(rep.accessToken, rep.refreshToken, tokenExpireDate)
    abp.utils.setCookieValue(
      this.authorization.encrptedAuthTokenName,
      rep.encryptedAccessToken,
      tokenExpireDate,
      abp.appPath
    )
  }

  getAbpConfig() {
    var ajax = Vue.prototype.$ajax
    var p1 = ajax("/api/auth/account/getcurrentuserinfo")
    var p2 = ajax("/api/auth/account/getcurrentuserpermissions")
    return Promise.all([p1, p2])
      .catch(({ err, hide }) => {
        console.log("err")
        console.log(err)
        console.log(err.response && err.response.status === 401)
        if (err.response && err.response.status === 401) {
          this.goAuthRedirect()
          hide()
        } else {
          document.body.innerHTML = '<h1 style="text-align:center;margin:40px 0;">404,服务器正在更新请稍后</h1>'
        }
        throw err
      })
      .then(([userInfo, permissions]) => {
        var result = {
          session: {
            userId: userInfo.id,
            user: {
              id: userInfo.id,
              name: userInfo.name,
            },
          },
          auth: {
            allPermissions: permissions,
          },
        }
        window.abp = extend(true, abp, result)
        // Object.assign(abp.session, userInfo.loginInformation)
      })
      .catch((e) => {
        console.log("e")
        console.log(e)
        if (e.response && e.response.status != 401) {
          document.body.innerHTML = '<h1 style="text-align:center;margin:40px 0;">404,服务启动失败请稍后</h1>'
        }
      })
  }
  // 将url中某个字符去掉
  funcUrlDel(name) {
    var loca = window.location
    var baseUrl = loca.origin + loca.pathname + "?"
    var query = loca.search.substr(1)
    if (query.indexOf(name) > -1) {
      var obj = {}
      var arr = query.split("&")
      for (var i = 0; i < arr.length; i++) {
        arr[i] = arr[i].split("=")
        obj[arr[i][0]] = arr[i][1]
      }
      delete obj[name]
      var targetUrl =
        baseUrl + JSON.stringify(obj).replace(/["{}]/g, "").replace(/:/g, "=").replace(/,/g, "&") + loca.hash
      return targetUrl
    } else {
      return loca.href
    }
  }

  // getAbpConfig() {
  //   return Vue.prototype
  //     .$ajax("/UserConfiguration/GetAll")
  //     .catch(({ err, hide }) => {
  //       if (err.response.status === 302) {
  //         this.goAuthRedirect()
  //         hide()
  //       } else {
  //         document.body.innerHTML = '<h1 style="text-align:center;margin:40px 0;">404,服务器正在更新请稍后</h1>'
  //       }
  //       throw err
  //     })
  //     .then((res) => {
  //       function toLowerCaseKeys(obj) {
  //         Object.keys(obj).forEach((el) => {
  //           obj[el.toLowerCase()] = obj[el]
  //           delete obj[el]
  //         })
  //       }
  //       toLowerCaseKeys(res.auth.allPermissions || {}) // 全部权限
  //       toLowerCaseKeys(res.auth.grantedPermissions || {}) // 已有权限
  //       toLowerCaseKeys(res.features.allFeatures || {})
  //       toLowerCaseKeys(res.setting.values || {})
  //       // 塞入abp.session等
  //       window.abp = extend(true, abp, res)
  //       Object.assign(abp.session, res.loginInformation)
  //     })
  //     .catch((e) => {
  //       console.log(e)
  //       document.body.innerHTML = '<h1 style="text-align:center;margin:40px 0;">404,服务启动失败请稍后</h1>'
  //     })
  // }
}
export default new Util()
