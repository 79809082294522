let config = [
  {
    meta: {
      title: "首页",
    },
    path: "/",
    redirect: "/Home",
  },
  {
    name: "test",
    path: "/test",
    meta: {
      title: "Test",
    },
    component: () => import(/* webpackChunkName: "group-other" */ "@/views/Common/Test.vue"),
  },
  {
    meta: {
      title: "404",
    },
    path: "*",
    component: () => import(/* webpackChunkName: "group-other" */ "@/views/Common/404"),
  },
  {
    name: "login",
    meta: {
      title: "登录",
    },
    path: "/Login",
    component: () => import(/* webpackChunkName: "group-other" */ "@/views/Common/Login"),
    props: (route) => ({
      returnUrl: route.query.returnUrl,
    }),
  },
  {
    name: "error",
    path: "/error",
    meta: {
      title: "错误",
    },
    component: () => import(/* webpackChunkName: "group-other" */ "@/views/Common/Error"),
    props: (route) => ({
      msg: decodeURI(route.query.msg || ""),
      detail: decodeURI(route.query.detail || ""),
      type: "error",
    }),
  },
  {
    name: "success",
    path: "/success",
    meta: {
      title: "成功",
    },
    component: () => import(/* webpackChunkName: "group-other" */ "@/views/Common/Error"),
    props: (route) => ({
      msg: decodeURI(route.query.msg || ""),
      detail: decodeURI(route.query.detail || ""),
      type: "success",
    }),
  },
]
export default config
