export default {
  extension: {
    widgetLabel: {
      card: "Card",
      user: "User",
      org: "Org",
      mould: "Mould",
      process: "Process",
      customer: "Customer",
      supplier: "Supplier",
      machining: "Machining",
      stock: "Stock",
    },

    setting: {
      cardFolded: "Folded",
      cardShowFold: "Show Fold",
      cardWidth: "Width Of Card",
      cardShadow: "Shadow",

      alertTitle: "Title",
      alertType: "Type",
      description: "Description",
      closable: "Closable",
      closeText: "Text On Close Btn",
      center: "Center",
      showIcon: "Show Icon",
      effect: "Effect",
    },
  },
}
