import { defineStore } from "pinia"
import getAdminUsers from "@/core/apis/manage"
export const useManageStore = defineStore("manage", {
  state: () => ({
    managements: [],
    appManagementIds: [],
    flowManagementIds: [],
    isAppManage: false,
    isFlowManage: false,
  }),
  actions: {
    async init() {
      if (abp.session.userId) {
        this.managements = await getAdminUsers()
        this.handleManagements()
      }
    },
    setManagements(users) {
      this.managements = users
      this.handleManagements()
    },
    handleManagements() {
      this.appManagementIds = []
      this.flowManagementIds = []
      this.managements.map((u) => {
        if (u.adminType.includes("AppAdmin")) this.appManagementIds.push(u.userId)
        if (u.adminType.includes("FlowAdmin")) this.flowManagementIds.push(u.userId)
      })
      this.isAppManage = this.appManagementIds.includes(abp.session.userId) || !this.appManagementIds.length
      this.isFlowManage = this.flowManagementIds.includes(abp.session.userId)
    },
  },
})
