<template>
  <v-app>
    <router-view />
    <div v-if="isMobile" class="nav-btn-box">
      <input id="box-buttons" ref="navBtn" type="checkbox" class="box-button" />
      <label class="circular" for="box-buttons"></label>
      <label class="fork" for="box-buttons"></label>
      <ul>
        <li v-for="(item, index) in navList" :key="index" class="box-item">
          <a href="#" @click="toPage(item.to)">
            <v-icon>{{ item.icon }}</v-icon>
          </a>
        </li>
      </ul>
    </div>
  </v-app>
</template>

<script>
import { debounce } from "@/utils/util"
import { useAvatarStore } from "@/store/avatar"
import { useManageStore } from "@/store/manage"
import { useMobileStore } from "@/store/mobile"
export default {
  name: "App",
  data() {
    return {
      navList: [
        { icon: "mdi-home", title: "首页", to: "/Home" },
        { icon: "icon-app iconfont", title: "应用", to: "/Application" },
        { icon: "mdi-folder", title: "项目", to: "/OneTeam" },
        { icon: "icon-todo iconfont", title: "待办", to: "/Todo" },
        { icon: "mdi-cog-outline", title: "设置", to: "/Setting/Info" },
      ],
    }
  },
  computed: {
    isMobile() {
      return this.$pinia.state.value.mobile.isMobile
    },
  },
  async created() {
    useAvatarStore().init()
    useManageStore().init()
    const { setMobile } = useMobileStore()
    setMobile()
    window.addEventListener(
      "resize",
      debounce(() => setMobile(), 100)
    )
  },
  methods: {
    toPage(path) {
      this.$refs.navBtn.click()
      if (this.$route.path === path) return
      this.$router.push(path)
    },
  },
}
</script>
<style lang="scss">
:root {
  --item-translateX: -100px;
}
.nav-btn-box {
  .box-button + .circular,
  .box-button + .circular + .fork,
  .box-button,
  .box-item {
    position: absolute;
    bottom: 20vh;
    right: 10px;
    z-index: 2;
  }
  .box-button {
    display: block;
    width: 60px;
    height: 60px;
    z-index: 3;
    opacity: 0;
    cursor: pointer;
  }
  .box-button + .circular {
    width: 40px;
    height: 40px;
    display: block;
    z-index: 1;
    border-radius: 50%;
    border: 5px solid rgba(64, 158, 255, 0.7);
    background: rgba(64, 158, 255, 0.2);
    box-shadow: 0px 0px 0px 10px rgba(64, 158, 255, 0.2);
    margin: 10px;
    transition: all 0.5s;
    transform-origin: 50% 50%;
  }

  .box-button:checked + .circular + .fork {
    width: 40px;
    height: 40px;
    display: block;
    z-index: 1;
    border-radius: 50%;
    background: rgba(64, 158, 255, 0.2);
    box-shadow: 0px 0px 0px 10px rgba(64, 158, 255, 0.2);
    margin: 10px;
    transition: all 0.5s;
  }

  .box-button:checked + .circular + .fork::after,
  .box-button:checked + .circular + .fork::before {
    content: "";
    width: 30px;
    height: 3px;
    display: block;
    z-index: 1;
    border-radius: 2.5px;
    background: rgba(64, 158, 255, 0.7);
    transition: all 0.5s;
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  .box-button:checked + .circular + .fork {
    transform: rotate(360deg);
  }
  .box-button:checked + .circular + .fork::after {
    transform: translate(-50%, -50%) rotate(45deg);
  }
  .box-button:checked + .circular + .fork::before {
    transform: translate(-50%, -50%) rotate(-45deg);
  }
  .box-button:checked + .circular {
    width: 0;
    height: 0;
    border: 0px;
    box-shadow: none;
  }
  .box-button:checked ~ ul .box-item {
    opacity: 1;
  }
  .box-item:nth-child(1) {
    transform: rotate(90deg);
  }
  .box-item:nth-child(2) {
    transform: rotate(45deg);
  }
  .box-item:nth-child(3) {
    transform: rotate(0deg);
  }
  .box-item:nth-child(4) {
    transform: rotate(315deg);
  }
  .box-item:nth-child(5) {
    transform: rotate(270deg);
  }

  .box-item:nth-child(1) a {
    transform: rotate(270deg);
  }
  .box-item:nth-child(2) a {
    transform: rotate(-45deg);
  }
  .box-item:nth-child(3) a {
    transform: rotate(0deg);
  }
  .box-item:nth-child(4) a {
    transform: rotate(45deg);
  }
  .box-item:nth-child(5) a {
    transform: rotate(90deg);
  }

  .box-button:checked ~ ul .box-item:nth-child(1) {
    transform: rotate(90deg) translateX(var(--item-translateX));
  }
  .box-button:checked ~ ul .box-item:nth-child(2) {
    transform: rotate(45deg) translateX(var(--item-translateX));
  }
  .box-button:checked ~ ul .box-item:nth-child(3) {
    transform: rotate(0deg) translateX(var(--item-translateX));
  }
  .box-button:checked ~ ul .box-item:nth-child(4) {
    transform: rotate(315deg) translateX(var(--item-translateX));
  }
  .box-button:checked ~ ul .box-item:nth-child(5) {
    transform: rotate(270deg) translateX(var(--item-translateX));
  }
  .box-button:checked ~ ul .box-item a {
    pointer-events: auto;
  }
  .box-button + .circular::before {
    top: 10px;
  }
  .box-button + .circular::after {
    top: -10px;
  }
  .box-item {
    display: block;
    width: 60px;
    height: 60px;
    opacity: 0;
    transition: 0.5s;
  }
  .box-item a {
    display: block;
    width: inherit;
    height: inherit;
    line-height: 58px;
    color: rgba(64, 158, 255, 0.7);
    background: rgba(64, 158, 255, 0.2);
    border-radius: 50%;
    text-align: center;
    text-decoration: none;
    font-size: 30px;
    pointer-events: none;
    transition: 0.2s;
    i {
      font-size: 30px;
    }
  }
}
</style>
