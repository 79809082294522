<template>
  <form-item-wrapper
    :designer="designer"
    :field="field"
    :rules="rules"
    :design-state="designState"
    :parent-widget="parentWidget"
    :parent-list="parentList"
    :index-of-parent-list="indexOfParentList"
    :sub-form-row-index="subFormRowIndex"
    :sub-form-col-index="subFormColIndex"
    :sub-form-row-id="subFormRowId"
  >
    <el-select
      v-show="!isReadMode"
      ref="fieldEditor"
      v-model="fieldModel"
      class="full-width-input user-select"
      :disabled="field.options.disabled"
      :size="field.options.size"
      :clearable="field.options.clearable"
      :filterable="field.options.filterable"
      :allow-create="field.options.allowCreate"
      :default-first-option="allowDefaultFirstOption"
      :automatic-dropdown="field.options.automaticDropdown"
      :multiple="field.options.multiple"
      :multiple-limit="field.options.multipleLimit"
      :placeholder="field.options.placeholder || i18nt('render.hint.selectPlaceholder')"
      :remote="field.options.remote"
      :remote-method="remoteQuery"
      popper-class="user-select-popper"
      @focus="handleFocusCustomEvent"
      @blur="handleBlurCustomEvent"
      @change="handleChangeEvent($event, users)"
    >
      <el-option v-for="item in users" :key="item.id" :label="item.name" :value="item.id">
        <div class="employee-info">
          <img v-if="item.avatarUrl" :src="item.avatarUrl" />
          <div v-else class="img" :style="{ backgroundColor: `#ccc` }">
            {{ item.name.charAt(0) }}
          </div>
          <div class="employee-info-container">
            <div class="employee-info-header">{{ item.name }}</div>
            <div class="employee-info-department">{{ item.organizationName || "非部门人员" }}</div>
          </div>
        </div>
      </el-option>
    </el-select>
    <template v-if="isReadMode">
      <span class="readonly-mode-field">{{ optionLabels(users) }}</span>
    </template>
    <v-dialog v-show="!isReadMode" v-model="dialogVisible" scrollable max-width="720px" style="z-index: 2222">
      <template #activator="{ on, attrs }">
        <el-button
          v-show="!isReadMode"
          v-bind="attrs"
          plain
          icon="el-icon-circle-plus-outline"
          class="full-width-btn"
          size="mini"
          :disabled="field.options.disabled"
          v-on="on"
          @click="handleVisibleClick"
        >
          选择用户
        </el-button>
      </template>
      <v-card v-if="dialogVisible">
        <v-card-title>选择用户</v-card-title>
        <v-divider></v-divider>
        <v-card-text style="height: 500px">
          <UserSelectWithOrganizationInner
            v-model="innerValue"
            :multiple="field.options.multiple"
          ></UserSelectWithOrganizationInner>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogVisible = false">取消</v-btn>
          <v-btn color="blue darken-1" dark @click="handleConfirm">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </form-item-wrapper>
</template>

<script>
import FormItemWrapper from "@/components/form-designer/form-widget/field-widget/form-item-wrapper"
import emitter from "@/utils/emitter"
import i18n, { translate } from "@/utils/i18n"
import fieldMixin from "@/components/form-designer/form-widget/field-widget/fieldMixin"

import getUsers from "@/core/apis/user"
import UserSelectWithOrganizationInner from "@/components/SearchSelect/UserSelectWithOrganizationInner"

export default {
  name: "user-widget",
  componentName: "FieldWidget",
  components: {
    FormItemWrapper,
    UserSelectWithOrganizationInner,
  }, // 必须固定为FieldWidget，用于接收父级组件的broadcast事件
  mixins: [emitter, fieldMixin, i18n],
  inject: ["refList", "globalOptionData", "globalModel"],
  props: {
    field: Object,
    parentWidget: Object,
    parentList: Array,
    indexOfParentList: Number,
    designer: Object,

    designState: {
      type: Boolean,
      default: false,
    },

    subFormRowIndex: {
      /* 子表单组件行索引，从0开始计数 */ type: Number,
      default: -1,
    },
    subFormColIndex: {
      /* 子表单组件列索引，从0开始计数 */ type: Number,
      default: -1,
    },
    subFormRowId: {
      /* 子表单组件行Id，唯一id且不可变 */ type: String,
      default: "",
    },
  },
  data() {
    return {
      oldFieldValue: null, // field组件change之前的值
      fieldModel: null,
      innerValue: null,
      rules: [],
      dialogVisible: false,
      users: [],
    }
  },
  computed: {
    allowDefaultFirstOption() {
      return !!this.field.options.filterable && !!this.field.options.allowCreate
    },
  },
  beforeCreate() {
    /* 这里不能访问方法和属性！！ */
  },

  created() {
    /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
         需要在父组件created中初始化！！ */
    this.initOptionItems()
    this.initFieldModel()
    this.registerToRefList()
    this.initEventHandler()
    this.buildFieldRules()

    this.handleOnCreated()
  },

  async mounted() {
    this.handleOnMounted()
    this.users = await getUsers()
  },

  beforeDestroy() {
    this.unregisterFromRefList()
  },

  methods: {
    handleVisibleClick() {
      this.dialogVisible = true
      this.innerValue = this.field.options.multiple ? this.fieldModel?.slice() || [] : this.fieldModel || null
    },
    handleConfirm() {
      this.dialogVisible = false
      this.fieldModel = this.field.options.multiple ? this.innerValue.slice() : this.innerValue
      if (this.fieldModel) {
        const res = []
        this.users.map((item) => {
          if (this.fieldModel.toString().split(",").includes(item.id.toString())) {
            res.push({ value: item.id, label: item.name })
          }
        })
        this.field.options.valueLabel = JSON.stringify(res)
        this.$emit("userWidgetChange", this.field.options.name, this.fieldModel)
      } else {
        this.$emit("userWidgetChange", this.field.options.name, null)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//

.full-width-input {
  width: 100% !important;
}
.full-width-btn {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
}
.el-select.user-select::v-deep {
  > div:not(.el-select__tags) > input,
  .el-select__tags {
    padding-left: 108px;
    > input {
      margin-left: 0;
    }
  }
}
.user-select-popper {
  .el-select-dropdown__item.selected {
    font-weight: 400;
    .employee-info {
      background-color: #e5e6e8 !important;
    }
  }
  .el-select-dropdown__item.hover {
    background-color: transparent !important;
    .employee-info {
      background-color: #f1f2f3;
    }
  }
  .el-select-dropdown__item {
    padding: 0 4px;
    height: 50px;
    .employee-info {
      border-radius: 8px;
      height: 50px;
      padding: 0 8px;
      margin: 0;
      border: none;
      box-sizing: border-box;
      overflow: hidden;
      line-height: 14px;
      white-space: nowrap;
      word-wrap: normal;
      cursor: pointer;
      > img,
      > .img {
        color: #171a1d;
        margin: 9px 8px 9px 0;
        width: 32px;
        height: 32px;
        border-radius: 16.6%;
        float: left;
        display: block;
      }
      > .img {
        text-align: center;
        line-height: 32px;
        font-size: 14px;
      }
      > .employee-info-container {
        margin: 5px 0;
        padding: 0;
        position: relative;
        overflow: hidden;
        > div.employee-info-header {
          font-size: 14px;
          color: #171a1d;
          margin: 0;
          overflow: hidden;
          line-height: 22px;
          height: 22px;
        }
        > div.employee-info-department {
          font-size: 12px;
          color: #747677;
          padding: 0;
          margin: 0;
          line-height: 20px;
          height: 20px;
          vertical-align: middle;
        }
      }
    }
  }
}
</style>
