export default [
  {
    path: "/Home",
    meta: {
      title: "首页",
      name: "Home",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Home/index"),
  },
  {
    path: "NoticeList",
    meta: {
      name: "NoticeList",
      title: "公告",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Home/NoticeList/index"),
  },
  {
    path: "/NoticeDetail/:noticeId",
    meta: {
      name: "NoticeDetail",
      title: "公告",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Home/NoticeList/components/NoticePreview"),
    props: (route) => {
      return {
        noticeId: Number(route.params.noticeId),
      }
    },
  },
]
export const name = "首页"
