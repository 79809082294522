import { getPageResult } from "@/plugins/axios"
let ops = null
export default async function getAdminUsers(refresh) {
  if (!ops || refresh) {
    ops = (async () => {
      const res = await getPageResult(`/api/oa/manage/getadminusers`)
      return res.reverse()
    })()
  }
  return await ops
}
