import Vue from "vue"
const $post = Vue.prototype.$post
export async function uploadByBase64(val) {
  let data, name
  if (typeof val === "object") {
    data = val.data
    name = val.name
  } else {
    data = val
  }
  return await $post.post(`/File/UploadByBase64`, {
    data,
    name,
  })
}
