export default [
  {
    path: "Setting",
    meta: {
      title: "平台管理",
      name: "Setting",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting"),
    children: [
      {
        path: "Info",
        meta: {
          title: "基本信息",
          name: "Setting",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Info"),
      },
      {
        path: "Permissions",
        meta: {
          title: "平台权限管理",
          name: "Setting",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Permissions"),
      },
      {
        path: "Providers",
        meta: {
          title: "外部应用",
          name: "Setting",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/Providers"),
      },
      {
        path: "DatasetManage",
        meta: {
          title: "数据集管理",
          name: "Setting",
        },
        component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/DatasetManage"),
      },
    ],
  },
  {
    path: "/ProviderSetting/:providerId",
    meta: {
      title: "外部数据集页面设置",
      name: "ProviderSetting",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/ProviderSetting"),
    props: (route) => {
      return {
        providerId: Number(route.params.providerId),
      }
    },
  },
  {
    path: "/Print",
    meta: {
      title: "打印",
      name: "Print",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/ProviderSetting/components/Print"),
  },
  {
    path: "/Contract",
    meta: {
      title: "合同生成",
      name: "Contract",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Setting/ProviderSetting/components/Print"),
  },
  {
    path: "/PrintSetting",
    meta: {
      title: "打印设置",
      name: "PrintSetting",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Design/PageSetting/components/PrintSetting"),
  },
  {
    path: "/ContractTemplate",
    meta: {
      title: "合同模板",
      name: "ContractTemplate",
    },
    component: () =>
      import(/* webpackChunkName: "group-Home" */ "@/views/Design/PageSetting/components/ContractTemplate"),
  },
]
export const name = "设置"
