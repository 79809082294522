// [{briefCode: "02"
// count: null
// discriminator: null
// displayName: "设计部"
// id: 8
// name: "设计部"
// parentId: null
// remarks: null
// sort: 0}]
import Vue from "vue"
let ops = {}
export default async function getOrganizations(refresh) {
  if (!ops.organization || refresh) {
    ops.organization = Vue.prototype.$getPageResult(`/api/services/app/Organization/GetTreeJson`)
  }
  return await ops.organization
}
