var render = function render(){var _vm=this,_c=_vm._self._c;return _c('form-item-wrapper',{attrs:{"designer":_vm.designer,"field":_vm.field,"rules":_vm.rules,"design-state":_vm.designState,"parent-widget":_vm.parentWidget,"parent-list":_vm.parentList,"index-of-parent-list":_vm.indexOfParentList,"sub-form-row-index":_vm.subFormRowIndex,"sub-form-col-index":_vm.subFormColIndex,"sub-form-row-id":_vm.subFormRowId}},[_c('el-cascader',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isReadMode),expression:"!isReadMode"}],ref:"fieldEditor",staticClass:"full-width-input",attrs:{"options":_vm.field.options.optionItems,"disabled":_vm.field.options.disabled,"size":_vm.field.options.size,"clearable":_vm.field.options.clearable,"filterable":_vm.field.options.filterable,"placeholder":_vm.field.options.placeholder || _vm.i18nt('render.hint.selectPlaceholder'),"props":{
      checkStrictly: _vm.field.options.checkStrictly,
      multiple: _vm.field.options.multiple,
      expandTrigger: 'hover',
      value: _vm.valueKey,
      label: _vm.labelKey,
      children: _vm.childrenKey,
    }},on:{"visible-change":_vm.hideDropDownOnClick,"expand-change":_vm.hideDropDownOnClick,"focus":_vm.handleFocusCustomEvent,"blur":_vm.handleBlurCustomEvent,"change":_vm.handleChangeEvent},model:{value:(_vm.fieldModel),callback:function ($$v) {_vm.fieldModel=$$v},expression:"fieldModel"}}),(_vm.isReadMode)?[_c('span',{staticClass:"readonly-mode-field"},[_vm._v(_vm._s(_vm.contentForReadMode))])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }