export default [
  {
    path: "/OA/Design/Form",
    meta: {
      title: "表单设计",
    },
    component: () => import(/* webpackChunkName: "group-Design" */ "@/views/Design/Form"),
    props: (route) => ({
      pageId: route.query.id ? parseInt(route.query.id) : null,
    }),
  },
  {
    path: "/OA/Design/WorkFlow",
    meta: {
      title: "流程设计",
    },
    component: () => import(/* webpackChunkName: "group-Design" */ "@/views/Design/WorkFlow"),
    props: (route) => ({
      pageId: route.query.id ? parseInt(route.query.id) : null,
      schemaId: route.query.schemaId ? parseInt(route.query.schemaId) : null,
    }),
  },
  {
    path: "/OA/Design/DataTable",
    meta: {
      title: "数据管理",
    },
    component: () => import(/* webpackChunkName: "group-Design" */ "@/views/Design/DataTable"),
    props: (route) => ({
      pageId: route.query.id ? parseInt(route.query.id) : null,
    }),
  },
  {
    path: "/OA/Design/PageSetting",
    meta: {
      title: "页面设置",
    },
    component: () => import(/* webpackChunkName: "group-Design" */ "@/views/Design/PageSetting"),
    props: (route) => ({
      pageId: route.query.id ? parseInt(route.query.id) : null,
    }),
  },
  {
    path: "/OA/Design/Report",
    meta: {
      title: "报表设计",
    },
    component: () => import(/* webpackChunkName: "group-lessUse" */ "@/views/Design/Report"),
    props: (route) => ({
      pageId: route.query.id ? parseInt(route.query.id) : null,
    }),
  },
]
export const name = "OA"
