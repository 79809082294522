import Vue from "vue"
import Vuetify from "vuetify/lib/framework"
import zhHans from "vuetify/lib/locale/zh-Hans"
import "@mdi/font/css/materialdesignicons.css" // Ensure you are using css-loader
zhHans.timePicker = {
  am: "上午",
  pm: "下午",
}

Vue.use(Vuetify)

export default new Vuetify({
  icons: {
    iconfont: "mdi", // 默认值 - 仅用于展示目的
  },
  lang: {
    locales: { zhHans },
    current: "zhHans",
  },
})
