<template>
  <form-item-wrapper
    :designer="designer"
    :field="field"
    :rules="rules"
    :design-state="designState"
    :parent-widget="parentWidget"
    :parent-list="parentList"
    :index-of-parent-list="indexOfParentList"
    :sub-form-row-index="subFormRowIndex"
    :sub-form-col-index="subFormColIndex"
    :sub-form-row-id="subFormRowId"
  >
    <el-select
      v-show="!isReadMode"
      ref="fieldEditor"
      v-model="fieldModel"
      class="full-width-input org-select"
      :disabled="field.options.disabled"
      :size="field.options.size"
      :clearable="field.options.clearable"
      :filterable="field.options.filterable"
      :allow-create="field.options.allowCreate"
      :default-first-option="allowDefaultFirstOption"
      :automatic-dropdown="field.options.automaticDropdown"
      :multiple="field.options.multiple"
      :multiple-limit="field.options.multipleLimit"
      :placeholder="field.options.placeholder || i18nt('render.hint.selectPlaceholder')"
      :remote="field.options.remote"
      :remote-method="remoteQuery"
      @focus="handleFocusCustomEvent"
      @blur="handleBlurCustomEvent"
      @change="handleChangeEvent($event, orgs)"
    >
      <el-option v-for="item in orgs" :key="item.id" :label="item.name" :value="item.id"></el-option>
    </el-select>
    <template v-if="isReadMode">
      <span class="readonly-mode-field">{{ optionLabels(orgs) }}</span>
    </template>
    <v-dialog v-show="!isReadMode" v-model="dialogVisible" scrollable max-width="720px">
      <template #activator="{ on, attrs }">
        <el-button
          v-show="!isReadMode"
          v-bind="attrs"
          plain
          icon="el-icon-circle-plus-outline"
          class="full-width-btn"
          size="mini"
          :disabled="field.options.disabled"
          v-on="on"
          @click="handleVisibleClick"
        >
          选择部门
        </el-button>
      </template>
      <v-card>
        <v-card-title>选择部门</v-card-title>
        <v-divider></v-divider>
        <v-card-text v-if="dialogVisible" style="height: 500px">
          <div class="d-flex h-100">
            <v-card-text class="org-list-wrap">
              <div class="px-4 py-2">
                <el-input v-model="query" size="medium" placeholder="查询" clearable></el-input>
              </div>
              <el-scrollbar view-class="organization-tree-wrap">
                <div class="px-2">
                  <organization-tree
                    :value="innerValue"
                    :multiple="field.options.multiple"
                    :query="query"
                    @input="handleInput"
                  ></organization-tree>
                </div>
              </el-scrollbar>
            </v-card-text>
            <v-divider vertical></v-divider>
            <div class="orgchoosen-list-wrap">
              <p>已选部门：</p>
              <v-chip v-for="org in activeOrgs" :key="org.id" class="mr-2 mb-2" label small>
                {{ org.name }}
                <v-icon class="text-body-2" @click="handleRemove(org.id)">mdi-close</v-icon>
              </v-chip>
            </div>
          </div>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialogVisible = false">取消</v-btn>
          <v-btn color="blue darken-1" dark @click="handleConfirm">确定</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </form-item-wrapper>
</template>

<script>
import FormItemWrapper from "@/components/form-designer/form-widget/field-widget/form-item-wrapper"
import emitter from "@/utils/emitter"
import i18n, { translate } from "@/utils/i18n"
import fieldMixin from "@/components/form-designer/form-widget/field-widget/fieldMixin"

import getOrganizations from "@/core/apis/organization"
import OrganizationTree from "@/components/OrganizationTree/OrganizationTree"

export default {
  name: "org-widget",
  componentName: "FieldWidget",
  components: {
    FormItemWrapper,
    OrganizationTree,
  }, // 必须固定为FieldWidget，用于接收父级组件的broadcast事件
  mixins: [emitter, fieldMixin, i18n],
  inject: ["refList", "globalOptionData", "globalModel"],
  props: {
    field: Object,
    parentWidget: Object,
    parentList: Array,
    indexOfParentList: Number,
    designer: Object,

    designState: {
      type: Boolean,
      default: false,
    },

    subFormRowIndex: {
      /* 子表单组件行索引，从0开始计数 */ type: Number,
      default: -1,
    },
    subFormColIndex: {
      /* 子表单组件列索引，从0开始计数 */ type: Number,
      default: -1,
    },
    subFormRowId: {
      /* 子表单组件行Id，唯一id且不可变 */ type: String,
      default: "",
    },
  },
  data() {
    return {
      oldFieldValue: null, // field组件change之前的值
      fieldModel: null,
      innerValue: null,
      rules: [],
      dialogVisible: false,
      orgs: [],
      query: "",
    }
  },
  computed: {
    allowDefaultFirstOption() {
      return !!this.field.options.filterable && !!this.field.options.allowCreate
    },
    activeOrgs() {
      const value =
        this.field.options.multiple && Array.isArray(this.innerValue)
          ? this.innerValue
          : this.innerValue
          ? [this.innerValue]
          : []
      return value.map((id) => {
        return this.orgs.find((o) => o.id === id) ?? { id: id, name: id }
      })
    },
  },
  beforeCreate() {
    /* 这里不能访问方法和属性！！ */
  },

  created() {
    /* 注意：子组件mounted在父组件created之后、父组件mounted之前触发，故子组件mounted需要用到的prop
         需要在父组件created中初始化！！ */
    this.initOptionItems()
    this.initFieldModel()
    this.registerToRefList()
    this.initEventHandler()
    this.buildFieldRules()

    this.handleOnCreated()
  },

  async mounted() {
    this.handleOnMounted()
    this.orgs = await getOrganizations()
  },

  beforeDestroy() {
    this.unregisterFromRefList()
  },

  methods: {
    handleVisibleClick() {
      this.dialogVisible = true
      this.innerValue = this.field.options.multiple ? this.fieldModel?.slice() || [] : this.fieldModel || null
    },
    handleConfirm() {
      this.dialogVisible = false
      this.fieldModel = this.field.options.multiple ? this.innerValue.slice() : this.innerValue
      if (this.fieldModel) {
        const res = []
        this.orgs.map((item) => {
          if (this.fieldModel.toString().split(",").includes(item.id.toString())) {
            res.push({ value: item.id, label: item.name })
          }
        })
        this.field.options.valueLabel = JSON.stringify(res)
      }
    },
    handleInput(e) {
      this.innerValue = e
    },
    handleRemove(id) {
      this.innerValue = this.field.options.multiple ? this.innerValue.filter((o) => o !== id) : null
    },
  },
}
</script>

<style lang="scss" scoped>
@import "../../../styles/global.scss"; //* form-item-wrapper已引入，还需要重复引入吗？ *//

.full-width-input {
  width: 100% !important;
}
.full-width-btn {
  position: absolute;
  left: 4px;
  top: 50%;
  transform: translate(0, -50%);
  z-index: 1;
}
.el-select.org-select::v-deep {
  > div:not(.el-select__tags) > input,
  .el-select__tags {
    padding-left: 108px;
    > input {
      margin-left: 0;
    }
  }
}
.org-list-wrap {
  width: 50%;
  height: 100%;
  overflow: auto;
}
.orgchoosen-list-wrap {
  width: 50%;
  height: 100%;
  overflow: auto;
  padding: 10px;
}
</style>
