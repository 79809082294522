export default [
  {
    path: "Todo",
    meta: {
      title: "我的待办",
      name: "Todo",
    },
    component: () => import(/* webpackChunkName: "group-Home" */ "@/views/Todo/index"),
  },
]
export const name = "待办"
