<!--  -->
<template>
  <div class="avatar-wrap">
    <v-badge color="green" :content="count" :value="!!count" bordered overlap>
      <v-tooltip bottom>
        <template #activator="{ on: tooltip, attrs: attrs1 }">
          <v-avatar :title="name" v-bind="{ ...attrs1, ...attrs }" v-on="{ ...tooltip, ...$listeners }">
            <img v-if="img && !errorImg" :src="img" :alt="name" @error="errorImg = true" />
            <span v-else class="white--text" :style="{ 'font-size': tFontSize }">
              {{ shortName }}
            </span>
          </v-avatar>
        </template>
        <span>{{ name }}</span>
      </v-tooltip>
    </v-badge>
    <p v-if="showName" class="avatar-name text-ellipsis">{{ name }}</p>
  </div>
</template>

<script>
import { getShortName2, getShortName1 } from "@/core/utils"
export default {
  // inheritAttrs: false,
  props: {
    name: String,
    img: String,
    // readonly: { type: Boolean, default: true },
    showName: { type: Boolean, default: false },
    size: [String, Number],
    fontsize: [String, Number],
    one: { type: Boolean, default: false },
    count: { type: [Number, String] },
  },
  data() {
    return { errorImg: false }
  },
  computed: {
    tSize() {
      return this.size || 32
    },
    tFontSize() {
      return (this.fontsize || (this.one ? 22 : 14)) + "px"
    },
    attrs() {
      return { size: this.tSize, color: "indigo", ...this.$attrs }
    },
    shortName() {
      return this.one ? getShortName1(this.name) : getShortName2(this.name)
    },
  },
}
</script>

<style lang="scss" scoped>
.avatar-wrap {
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  vertical-align: top;
  & + & {
    margin-left: 8px;
  }
  .avatar-name {
    font-size: 12px;
    line-height: 1;
    max-width: 4em;
    text-overflow: clip;
  }
}
</style>
