import Vue from "vue"

export { previewImage } from "ele-extension"
import "ele-extension/lib/eleExtension.css"

/**
 * 在[p]没加载完成前转圈圈
 * @param {Promise} p 等待项
 * @param {object} options 配置
 * @returns {Promise}
 */
export function runAsync(p, options = {}) {
  return new Promise((resolve, reject) => {
    options = Object.assign(
      {
        fullscreen: true,
        lock: true,
        text: "请求中",
        // background: 'rgba(0, 0, 0, 0.7)'
      },
      options
    )
    const loading = Vue.prototype.$loading(options)
    try {
      p.then((res) => {
        resolve(res)
        loading.close()
      }).catch((err) => {
        reject(err)
        loading.close()
      })
      // .finally(() => loading.close());//fixbug 有微任务问题
    } catch (err) {
      reject(err)
      loading.close()
    }
  })
}

/**
 *  格式化数字，如果是都是0就不要丢，否则根据第一个参数进行省略
 * @param {number} num 待处理的值
 * @param {number} fractionDigits 保留几位
 * @param {boolean} showZero 是否展示0，默认不展示
 * @param {(num: number)=>number} convert 后处理函数
 * @returns {string} 目标值
 */
export function toFixed(num, fractionDigits = 2, showZero = false, convert) {
  num = Number(num)
  if (isNaN(num) || !num) return showZero ? "0" : ""
  const input = parseFloat(num)
  if (Number.isNaN(input)) {
    return ""
  }
  const output = convert ? convert(input) : input
  const base = Math.pow(10, fractionDigits)
  const rounded = Math.round(output * base) / base
  if (!rounded && !showZero) return ""
  return rounded.toString()

  // return Number.isInteger(num) ? String(num) : num.toFixed(fractionDigits);
}

/**
 * 获得一个字的头像
 * @param {string} name 全名
 * @returns {string} 一字名
 */
export function getShortName1(name) {
  if (!name || typeof name !== "string") return "模"
  return name.slice(0, 1)

  // if (name.length > 2) {
  //   if (/[a-zA-Z0-9]/.test(name)) return name.slice(0, 1);
  //   return name.slice(-2);
  // } else {
  //   return name;
  // }
}
/**
 * 动态获得名字用于头像；0，1，2个直接丢原值，3个4个字的丢后两位 （对应三字名与复姓）
 * @param {string} name 全名
 * @returns {string} 短名
 */
export function getShortName2(name) {
  if (!name || typeof name !== "string") return "模"
  var nameArr = name.split("")
  switch (name.length) {
    case 0:
    case 1:
    case 2:
      return name
    case 3:
      return nameArr[1] + nameArr[2]
    case 4:
      return nameArr[2] + nameArr[3]
    default:
      return nameArr[0]
  }
}

/**
 * 是否是Promise
 * @param {Promise} p 待判断的值
 * @returns {boolean}
 */
export function isPromise(p) {
  return p instanceof Promise
}
/**
 * 根据key去重对象数组
 * @param {[]} arr 未去重的对象数组
 * @param {string} key 键
 * @returns {object[]} 对象数组
 */
export function uniqueByKey(arr, key) {
  let map = new Map()
  let array = new Array() // 数组用于返回结果
  for (let i = 0; i < arr.length; i++) {
    if (typeof arr[i] !== "object") {
      throw new Error("只允许去重对象数组")
    }
    if (!map.has(arr[i][key])) {
      map.set(arr[i][key], true)
      array.push(arr[i])
    }
  }
  return array
}

//
/**
 * 拷贝
 * @param  {Boolean} bool 是否深拷贝
 * @param  {Object} obj 目标值
 * @param  {...Object} objects 来源值
 * @returns
 */
export const extend = function (...args) {
  let options,
    name,
    src,
    srcType,
    copy,
    copyIsArray,
    clone,
    target = args[0] || {},
    i = 1,
    length = args.length,
    deep = false
  if (typeof target === "boolean") {
    deep = target
    target = args[i] || {}
    i++
  }
  if (typeof target !== "object" && typeof target !== "function") {
    target = {}
  }
  // 作为一个对象的方法被调用时；a.extend({})
  if (i === length) {
    target = this
    i--
  }
  for (; i < length; i++) {
    if ((options = args[i]) !== null) {
      for (name in options) {
        src = target[name]
        copy = options[name]
        if (target === copy) {
          continue
        }
        srcType = Array.isArray(src) ? "array" : typeof src
        if (deep && copy && ((copyIsArray = Array.isArray(copy)) || typeof copy === "object")) {
          if (copyIsArray) {
            copyIsArray = false
            clone = src && srcType === "array" ? src : []
          } else {
            clone = src && srcType === "object" ? src : {}
          }
          target[name] = extend(deep, clone, copy)
        } else if (copy !== undefined) {
          target[name] = copy
        }
      }
    }
  }
  return target
}

let isProduction = process.env.NODE_ENV === "production" || process.env.NODE_ENV === "stage"

export function getUrl(str, complete) {
  if (!str) return
  if (str.startsWith("http")) return str
  if (complete) return (process.env.VUE_APP_URL || location.origin) + str
  if (isProduction) return location.origin + str
  return process.env.VUE_APP_URL + str
}

/**
 * 从queryString中取[name]对应的值
 * @param {string} name 关键字
 * @returns {string} value
 */
export function getUrlParam(name) {
  var reg = new RegExp("(^|&)" + name + "=([^&]*)(&|$)")
  var r = window.location.search.substr(1).match(reg)
  if (r !== null) return decodeURIComponent(r[2])
  return null
}
