export default {
  render: {
    hint: {
      prompt: "Prompt",
      confirm: "OK",
      cancel: "Cancel",
      defaultActiveText: "True",
      defaultInactiveText: "False",

      selectPlaceholder: "Pick some item",
      timePlaceholder: "Select time",
      startTimePlaceholder: "Start time",
      endTimePlaceholder: "End time",
      datePlaceholder: "Select date",
      startDatePlaceholder: "Start date",
      endDatePlaceholder: "End date",
      blankCellContent: "--",

      uploadError: "Upload error: ",
      uploadExceed: "The maximum number(${uploadLimit}) of file uploads has been exceeded.",
      unsupportedFileType: "Unsupported format: ",
      fileSizeExceed: "File size out of limit: ",
      refNotFound: "Ref not found: ",
      fieldRequired: "Input value should be not null.",
      invalidNumber: "Invalid number format",
      selectFile: " File...",
      downloadFile: "Download",
      removeFile: "Remove",
      validationFailed: "Form validation failed",

      subFormAction: "Action",
      subFormAddAction: "Add",
      subFormAddActionHint: "add new row",
      insertSubFormRow: "insert new row",
      deleteSubFormRow: "delete this row",
      nonSubFormType: "The type of widget don't match sub-form",
    },
  },
}
