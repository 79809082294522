import { render, staticRenderFns } from "./file-upload-widget.vue?vue&type=template&id=bb9c0122&scoped=true"
import script from "./file-upload-widget.vue?vue&type=script&lang=js"
export * from "./file-upload-widget.vue?vue&type=script&lang=js"
import style0 from "./file-upload-widget.vue?vue&type=style&index=0&id=bb9c0122&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "bb9c0122",
  null
  
)

export default component.exports